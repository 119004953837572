<template>
	<main class="container">
		<div class="content">
			<div class="content_head">
				<div class="inner">
					<div class="breadcrumb">
						<ul>
							<li><a href="#" class="ico_home" title="Home"></a></li>
							<li>이메일무단수집거부</li>
						</ul>
					</div>
					<h2 class="page_title">이메일무단수집거부</h2>
				</div>
			</div>
			<div class="content_body">
				<div class="inner">
					<div class="policy_section">
						<div class="policy_group">
							<p>
								누구든지 인터넷 홈페이지 운영자 또는 관리자의 사전 동의없이 인터넷 홈페이지에서 자동으로 전자우편주소를 수집하는 프로그램이나 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니되며 이를 위반시 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 74조 제 1항 제 5호에 의해 1년 이하의 징역 또는 1천만원 이하의 벌금에 처할 수 있음을 유념하시기 바랍니다.
							</p>
						</div>
						<div class="policy_group">
<!--							<p>-->
							<h3 class="policy_title">정보통신망 이용촉진 및 정보보호 등에 관한 법률</h3>
							<strong>1. 제50조의2 (전자우편주소의 무단수집행위 등 금지)</strong>
							<strong>2. 제74조 (벌칙)</strong>
<!--							</p>-->
						</div>
						<div class="policy_group">
<!--							<p>-->
								<strong>제50조의2 (전자우편주소의 무단 수집행위 등 금지)</strong>
							<ol class="ol_deciaml_01">
								<li>
									<span>1.</span>
									누구든지 인터넷 홈페이지 운영자 또는 관리자의 사전 동의 없이 인터넷 홈페이지에서 자동으로 전자우편주소를 수집하는 프로그램이나 그 밖의 기술적 장치를 이용하여 전자우편주소를 수집하여서는 아니된다.
								</li>
								<li>
									<span>2.</span>
									누구든지 제1항을 위반하여 수집된 전자우편주소를 판매·유통하여서는 아니된다.
								</li>
								<li>
									<span>3.</span>
									누구든지 제1항 및 제2항의에 따라 수집·판매 및 유통이 금지된 전자우편주소임을 알면서도 이를 정보 전송에 이용하여서는 아니된다.
								</li>
							</ol>
<!--							</p>-->
						</div>
						<div class="policy_group">
<!--							<p>-->
								<strong>제74조(벌칙) 다음 각호의 어느 하나에 해당하는 자는 1년 이하의 징역 또는 1천만원 이하의 벌금에 처한다.</strong>
							<ol class="ol_deciaml_01">
								<li>
									<span>1.</span>
									제50조제6항을 위반하여 기술적 조치를 한 자
								</li>
								<li>
									<span>2.</span>
									제50조제8을 위반하여 광고성 정보를 전송한 자
								</li>
								<li>
									<span>3.</span>
									제50조의2를 위반하여 전자우편주소를 수집ㆍ판매ㆍ유통 또는 정보 전송에 이용한 자
								</li>
							</ol>
<!--							</p>-->
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	name: "email"
}
</script>

<style scoped>

</style>